@font-face {
  font-family: 'gibsonbold';
  src: url('/fonts/gibsonbold-webfont.woff2') format('woff2'),
       url('/fonts/gibsonbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'gibsonbold_italic';
//   src: url('/fonts/gibsonboldit-webfont.woff2') format('woff2'),
//        url('/fonts/gibsonboldit-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'gibsonitalic';
//   src: url('/fonts/gibsonitalic-webfont.woff2') format('woff2'),
//        url('/fonts/gibsonitalic-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'gibsonlight';
  src: url('/fonts/gibsonlight-webfont.woff2') format('woff2'),
       url('/fonts/gibsonlight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'gibsonlight_italic';
//   src: url('/fonts/gibsonlightit-webfont.woff2') format('woff2'),
//        url('/fonts/gibsonlightit-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'gibsonregular';
  src: url('/fonts/gibsonregular-webfont.woff2') format('woff2'),
       url('/fonts/gibsonregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gibsonsemibold';
  src: url('/fonts/gibsonsemibd-webfont.woff2') format('woff2'),
       url('/fonts/gibsonsemibd-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'gibsonsemibold_italic';
//   src: url('/fonts/gibsonsemibdit-webfont.woff2') format('woff2'),
//        url('/fonts/gibsonsemibdit-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }