body {
  font-size: .875rem;
  color: #4f566b;
  line-height: 1.6;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.table-with-sm-thumbnail img {
  max-width: 128px;
  max-height: 128px;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  @include media-breakpoint-down(sm) {
    width: 200px;
    max-width: none;
    transition: all .3s;
    transform: translateX(-100%);
    &.open {
      transform: none;
    }
  }

}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 41px; /* Height of navbar */
  height: calc(100vh - 41px);
  padding-top: .5rem;
  overflow-x: hidden;
  bottom: 0;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: $primary;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.nav-link-heading {
  margin: 1px 0;
  font-size: 1em;
  text-transform: uppercase;
}



/*
 * Navbar
 */

.navbar-brand {
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

hr {
  margin: 40px 0;
  @include media-breakpoint-down(sm) {
    // margin: 40px 0;
  }
}

pre {
	max-height: 40em;
	overflow: auto;
}

footer {
  height: 200px;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5em 0;
}

.top-menu {
  flex-direction: row;
  & > .nav-item {
    margin-left: 30px;
    @include media-breakpoint-down(sm) {
      margin-left: 15px;
    }
  }
}

.navbar-nav .dropdown-menu  {
  position: absolute;
  margin-left: -20px;
}

.gallery > div {
  margin-bottom: 30px;
}

div.code-toolbar>.toolbar {
  right: 40px;
  top: 20px;
  button {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.code-heading {
  background: #545454;
  color: #fff;
  padding: 1em;
  margin: 0 0 -0.9em;
  border-radius: .5em .5em 0 0;
}
pre.code_box {
  background-color: #141414;
  color: #f9ee9a;
}

p code, li code {
  color: #2a2f45;
  background: #f7fafc;
  padding: 0 3px;
  margin: 0 1px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.05);
  font-weight: 500;
  font-size: .9em;
}
